import type { NavigationGuardWithThis, RouteRecordRaw } from 'vue-router'
import { auth, getCurrentUser } from '@/firebase'
import { useAuthStore } from '@/stores/auth'
import { getRedirectResult } from '@firebase/auth'

const teamsRouteNames = Object.freeze({
  'teams.signupAlt': 'teams-signup-alt',
  'teams.signup': 'teams-signup',
  'teams.signupWithInvite': 'teams.signupWithInvite',
  'teams.login': 'teams-login',
  'teams.needsGitHubInstall': 'teams-needs-github-install',
  'teams.dashboard': 'teams-home',
  'teams.slackSetup': 'teams-setup-slack',
  'teams.slackSetup.finish': 'teams-finish-slack-setup',

  'teams.codesecurity.home': 'teams.codesecurity.home',
  'teams.phishing.home': 'teams.phishing.home',
  'teams.phishing.viewCampaign': 'teams.phishing.viewCampaign',
  'teams.keyring.home': 'teams.keyring.home',
  'teams.settings': 'teams.settings',
})

const teamsRoutes = <RouteRecordRaw[]>[
  {
    path: '/teams/signup',
    name: teamsRouteNames['teams.signupAlt'],
    meta: { teams: { guestOnly: false }, layout: 'auth' },
    component: () => import('@/views/Teams/Signup.vue'),
  },
  {
    path: '/t/signup',
    name: teamsRouteNames['teams.signup'],
    meta: { teams: { guestOnly: true }, layout: 'auth' },
    component: () => import('@/views/Teams/Signup.vue'),
  },
  {
    path: '/t/signup/:inviteCode',
    name: teamsRouteNames['teams.signupWithInvite'],
    meta: { teams: { guestOnly: true }, layout: 'auth' },
    component: () => import('@/views/Teams/Signup.vue'),
  },
  {
    path: '/t/login',
    name: 'teams-login',
    meta: { teams: { guestOnly: true }, layout: 'auth' },
    component: () => import('@/views/Teams/Login.vue'),
  },
  {
    path: '/t/needs-github-install',
    name: 'teams-needs-github-install',
    meta: { teams: { guestOnly: true }, layout: 'auth' },
    component: () => import('@/views/Teams/NeedsGitHubInstall.vue'),
  },
  {
    path: '/t',
    name: 'teams-home',
    meta: { teams: { requireAuth: true }, layout: 'teams-dashboard' },
    component: () => import('@/views/Teams/Dashboard/Dashboard.vue')
  },
  {
    path: '/t/setup/slack',
    name: 'teams-setup-slack',
    component: () => import('@/views/Teams/SetupSlack.vue'),
    meta: { teams: { requireAuth: true }, layout: 'blank' },
  },
  {
    path: '/t/setup/slack/finish',
    name: 'teams-finish-slack-setup',
    component: () => import('@/views/Teams/VerifySlackLink.vue'),
    meta: { teams: { requireAuth: true }, layout: 'blank' },
  },
  {
    path: '/t/code-security',
    name: teamsRouteNames['teams.codesecurity.home'],
    component: () => import('@/views/Teams/CodeSecurity/CodeSecurity.vue'),
    meta: { teams: { requireAuth: true }, layout: 'teams-dashboard' },
  },
  {
    path: '/t/phishing',
    meta: { teams: { requireAuth: true }, layout: 'teams-dashboard' },
    children: [
      {
        path: '',
        name: teamsRouteNames['teams.phishing.home'],
        component: () => import('@/views/Teams/PhishingDefense/PhishingDefense.vue'),
      },
      {
        path: 'campaigns/:id',
        name: teamsRouteNames['teams.phishing.viewCampaign'],
        component: () => import('@/views/Teams/PhishingDefense/ViewCampaign.vue'),
      }
    ]
  },
  {
    path: '/t/keyring',
    name: teamsRouteNames['teams.keyring.home'],
    component: () => import('@/views/Teams/Keyring.vue'),
    meta: { teams: { requireAuth: true }, layout: 'teams-dashboard' },
  },
  {
    path: '/t/settings/:tab',
    alias: '/t/settings',
    name: teamsRouteNames['teams.settings'],
    component: () => import('@/views/Teams/Settings.vue'),
    meta: { teams: { requireAuth: true }, layout: 'teams-dashboard' },
  }
]

// on teams.locktor.io, add a root path
if (window && window.location) {
  if (window.location.host.startsWith('teams.')) {
    teamsRoutes.push({
      path: '/',
      redirect: '/t',
    })
  }
}

const teamsGuard = <NavigationGuardWithThis<undefined>>(
  async (to, from, next) => {
    //

    const requireAuth = to.matched.some(record => record.meta.teams?.requireAuth)
    const guestOnly = to.matched.some(record => record.meta.teams?.guestOnly)
    // If we don't need to guard this page (i.e. it's ok for every
    // kind of user to see), we can move forward quickly
    if (requireAuth === undefined) {
      return next()
    }

    // else...
    const authStore = useAuthStore()
    const someonesLoggedIn = authStore.signedIn || await getCurrentUser()

    if (guestOnly && someonesLoggedIn) {
      const isSignInRedirect = await getRedirectResult(auth)
      if (isSignInRedirect) {
        // the caller is on our `to` page. let them handle the result of their call
        authStore.setSignInRedirectResult(isSignInRedirect)

        return next()
      }

      if (to.query.redirect) {
        return next(to.query.redirect as string)
      }

      return next({ name: 'teams-home' })
    }

    if (requireAuth && !someonesLoggedIn) {
      return next({
        name: 'teams-signup',
        query: { redirect: to.fullPath }
      })
    }

    return next()
  }
)

export { teamsRoutes, teamsGuard, teamsRouteNames }
